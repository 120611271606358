import { MessageSharp } from "@mui/icons-material";
import { Button, TextField } from "@mui/material";
import { object } from "prop-types";
import React, { FunctionComponent, useEffect, useState } from "react";
import Loader from "react-spinners/BarLoader";
import { AppStore } from "../../../AppStore";
import Popup from "../../../components/popup/Popup";
import { downloadCSV, downloadKML } from "../../../helpers/file";
import { FormatDateToString, FormatDateToUTCString } from "../../../helpers/time";
import { TimeSeriesUpload, Upload } from "../../../services/upload/models/Upload";
import { UploadService } from "../../../services/upload/UploadService";

import styles from "../styles/Components.css";

interface Props {
  visible: boolean;
  onDismiss: () => void;
  keys: string[];
  timeSeries: [Date, Date, number];
}

const DownloadModal: FunctionComponent<Props> = (props) => {

  const [state, actions] = AppStore.systems.use();
  const [start, setStart] = useState<Date>();
  const [end, setEnd] = useState<Date>();
  const [average, setAverage] = useState<number>(0);
  const [downloading, setDownloading] = useState(false);

  const handleFailedDownload = (error) => {
    console.log(error);
  };

  const handleFinishDownloading = () => {
    setDownloading(false);
  };

  const handleCancel = () => {
    const [from, to, average] = props.timeSeries;

    setAverage(average);
    setStart(from);
    setEnd(to);
    setDownloading(false);
    props.onDismiss();
  };

  const handlePreprocessData = (uploads: TimeSeriesUpload[]) => {
    const processed: Object[] = [];

    uploads.forEach((upload) => {
      const flattened = {};

      // flattened["Date Received"] = upload.dateReceived;
      let averagedStartDateFixed = new Date(upload.averagedStartDate);
      let averagedEndDateFixed = new Date(upload.averagedEndDate);
      if (average != 0)
      {
        const timeZoneChange = new Date().getTimezoneOffset()*-60000;
        averagedStartDateFixed = new Date(averagedStartDateFixed.getTime()+timeZoneChange);
        averagedEndDateFixed = new Date(averagedEndDateFixed.getTime()+timeZoneChange);
      }
      flattened["Averaged Start Date (UTC)"] = FormatDateToUTCString(averagedStartDateFixed);
      flattened["Averaged End Date (UTC)"] = FormatDateToUTCString(averagedEndDateFixed);

      upload.dataPoints.forEach((point) => {
        flattened[`${point.dataPoint.ssid}:${point.dataPoint.name} (${point.dataPoint.units})`] = point.dataPoint.value;
        // flattened["Sub System"] = point.dataPoint.ssid;
        // flattened[`${point.dataPoint.name} (${point.dataPoint.units})`] = point.dataPoint.value;
      });

      // console.log(flattened)

      processed.push(flattened);
    });

    return processed;
  };

  const handleKMLDownload = () => {
    if (!start) return;

    setDownloading(true);

    const keys: string[] = []
    props.keys.forEach(key => keys.push(key.split(" ")[0]))


    if (state.highlighted)
    {
      const timeZoneChange = new Date().getTimezoneOffset()*60000;
      let endModified = end;
      if (end)
      {
        endModified = new Date(end.getTime()+timeZoneChange);
      }
      UploadService.getPrimaryUploadsForDevices(
        state.highlighted.sid,
        keys,
        average,
        new Date(start.getTime()),
        endModified,
      )
        .then(handlePreprocessData)
        .then(downloadToKML)
        .then(handleFinishDownloading)
        .catch(handleFailedDownload);
    }
  };

  const handleDownload = () => {
    if (!start) return;

    setDownloading(true);

    const keys: string[] = []
    props.keys.forEach(key => keys.push(key.split(" ")[0]))

    if (state.highlighted)
    {
      const timeZoneChange = new Date().getTimezoneOffset()*60000;
      let endModified = end;
      if (end)
      {
        endModified = new Date(end.getTime()+timeZoneChange);
      }

      UploadService.getPrimaryUploadsForDevices(
        state.highlighted.sid,
        keys,
        average,
        new Date(start.getTime()),
        endModified
      )
        .then(handlePreprocessData)
        .then(downloadToCSV)
        .then(handleFinishDownloading)
        .catch(handleFailedDownload);
    }
  };

  const downloadToKML = (uploads :Object[]) => {
    const keys: string[] = [];
    // // keys.push("Date Uploaded");
    // keys.push("Averaged Start Date (UTC)");
    // keys.push("Averaged End Date (UTC)");
    // console.log(uploads);

    interface measurementInterface {
      [key: string]: string;
      }

    let time;
    let lat;
    let lon;

    let KML = '<?xml version="1.0" encoding="UTF-8"?>\n' + '<kml xmlns="http://www.opengis.net/kml/2.2" xmlns:gx="http://www.google.com/kml/ext/2.2" xmlns:kml="http://www.opengis.net/kml/2.2" xmlns:atom="http://www.w3.org/2005/Atom">\n' + '<Document>\n';
    KML += '<name>'+state.highlighted?.sid+"_"+start?.toDateString()+'</name>';
    KML += '<gx:CascadingStyle kml:id="__managed_style_2BE221F773281733B660"><Style><IconStyle><scale>0.9</scale><Icon><href>https://earth.google.com/earth/rpc/cc/icon?color=1976d2&amp;id=2000&amp;scale=4</href></Icon><hotSpot x="64" y="128" xunits="pixels" yunits="insetPixels"/></IconStyle><LabelStyle></LabelStyle><LineStyle><width>1.5</width></LineStyle><PolyStyle></PolyStyle><BalloonStyle></BalloonStyle></Style></gx:CascadingStyle><gx:CascadingStyle kml:id="__managed_style_1A2AE39035281733B660"><Style><IconStyle><scale>0.75</scale><Icon><href>https://earth.google.com/earth/rpc/cc/icon?color=1976d2&amp;id=2000&amp;scale=4</href></Icon><hotSpot x="64" y="128" xunits="pixels" yunits="insetPixels"/></IconStyle><LabelStyle></LabelStyle><LineStyle></LineStyle><PolyStyle></PolyStyle><BalloonStyle></BalloonStyle></Style></gx:CascadingStyle><StyleMap id="__managed_style_03B6847740281733B660"><Pair><key>normal</key><styleUrl>#__managed_style_1A2AE39035281733B660</styleUrl></Pair><Pair><key>highlight</key><styleUrl>#__managed_style_2BE221F773281733B660</styleUrl></Pair></StyleMap>'
    Object.entries(uploads).map(([key, value]) => {
      let measurementPair: measurementInterface = {};
      Object.entries(value).map(([theKey, theValue]) => {
        // Pretty straightforward - use key for the key and value for the value.
        // Just to clarify: unlike object destructuring, the parameter names don't matter here.
        if (theKey.indexOf("Date") > -1)
        {
          time = theValue;
        }
        else if(theKey.indexOf('LAT') > -1)
        {
          lat = theValue;
        }
        else if (theKey.indexOf('LON') > -1)
        {
          lon = theValue;
        }
        else
        {
          measurementPair[theKey.substring(theKey.indexOf(':')+1, theKey.length)] = theValue;
        }
    })
    KML += '<Placemark id=\"'+key+'\">';
    KML += '<name>'+time+'</name>';
    KML += '<description>';
    Object.entries(measurementPair).map(([name, measurementValue]) => {
      KML += name+' = '+measurementValue+'\n';
    });
    KML += "Time: "+time;
    KML += '</description>';
    KML += '<styleUrl>#__managed_style_03B6847740281733B660</styleUrl>'
    KML += '<Point><coordinates>'+lon+","+lat+",0"+'</coordinates></Point>';
    KML += '</Placemark>';
  });

    KML += '</Document>\n' + '</kml>';
    console.log(KML);
    downloadKML(
      state.highlighted? state.highlighted.sid: "NULL",
      KML
    );

  };

  const downloadToCSV = (uploads) => {
    const keys: string[] = [];
    // keys.push("Date Uploaded");
    keys.push("Averaged Start Date (UTC)");
    keys.push("Averaged End Date (UTC)");

    props.keys.forEach((key) => {
      keys.push(key);
    });

    const options = {
      emptyFieldValue: "N/A",
      keys: keys,
    };

    downloadCSV(
      state.highlighted ? state.highlighted.sid : "system",
      uploads,
      options
    );
  };

  useEffect(() => {
    const [from, to, average] = props.timeSeries;

    setStart(from);
    setEnd(to);
    setAverage(average);
  }, [props.timeSeries]);

  return (
    <Popup
      visible={props.visible}
      onDismiss={props.onDismiss}
      subtitle={state.highlighted?.sid}
      title="System Graph"
    >
      <form className={styles.downloadContainer} noValidate>
        <div className={styles.downloadRangeContainer}>
          <TextField
            id="Start_date"
            label="Start Date"
            type="date"
            value={FormatDateToString(start)}
            onChange={(e) => setStart(new Date(e.target.value + 'T00:00'))}
            className={styles.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            id="End_date"
            label="End Date"
            type="date"
            value={FormatDateToString(end)}
            onChange={(e) => setEnd(new Date(e.target.value + 'T00:00'))}
            className={styles.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className={styles.downloadButtonContainer}>
          {downloading ? (
            <Loader />
          ) : (
            <>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button onClick={handleKMLDownload}>Download KML</Button>
              <Button onClick={handleDownload}>Download Excel</Button>
            </>
          )}
        </div>
      </form>
    </Popup>
  );
};

export default DownloadModal;
