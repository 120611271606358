import { ENV } from "./env";

const version = "v2"

export const API = {

    local: {
        session: "session-token"
    },
    
    webSockets: {
        uploads: `${ENV.connections.api}/${version}/live/uploads`,
        updates: `${ENV.connections.api}/${version}/live/updates`,

        commands: {

            // User
            userCreated: "UserCreated",
            userUpdated: "UserUpdated",
            userAuthenticated: "UserAuthenticated",
            userAssignedToCompany: "UserAssignedToCompany",
            userRemovedFromCompany: "UserRemovedFromCompany",

            // Company
            companyCreated: "CompanyCreated",
            companyUpdated: "CompanyUpdated",
            companyChangeEventCreated: "CompanyChangeEventCreated",

            // System
            systemUpload: "SystemUpload",
            systemCreated: "SystemCreated",
            systemUpdated: "SystemUpdated",
            systemUploaded: "SystemUploaded",
            systemAssignedToCompany: "SystemAssignedToCompany",
            systemRemovedFromCompany: "SystemRemovedFromCompany",

            // Change Event
            changeEventCreated: "ChangeEventCreated",

        }
    },

    server: {
        healthCheck: `${ENV.connections.api}/hc`, // GET

        account: {
            details: `${ENV.connections.api}/${version}/users/%id%`, // GET
            auth0Details: `${ENV.connections.api}/${version}/users/auth0/%id%`, // GET
            list: `${ENV.connections.api}/${version}/users/`, // GET
        },

        auth0: {
            users: `https://cors-anywhere.herokuapp.com/https://${ENV.auth0.domain}/api/v2/users`
        },

        company: {
            list: `${ENV.connections.api}/${version}/companies`,
            single: `${ENV.connections.api}/${version}/companies/%id%`,
            users: `${ENV.connections.api}/${version}/companies/%id%/users`,
            systems: `${ENV.connections.api}/${version}/companies/%id%/systems`,
            correlations: `${ENV.connections.api}/${version}/companies/%id%/correlations`,
            system: {
                nickname: `${ENV.connections.api}/${version}/companies/%id%/system/%systemId%/nickname`
            },
            devices: `${ENV.connections.api}/${version}/companies/%id%/devices`,
            events: `${ENV.connections.api}/${version}/companies/%id%/events`,
            projects: `${ENV.connections.api}/${version}/companies/%id%/projects`,
            project: {
                systems: `${ENV.connections.api}/${version}/companies/%id%/%project%/systems`,
            }
        },

        user: {
            list: `${ENV.connections.api}/${version}/users`
        },

        system: {
            list: `${ENV.connections.api}/${version}/systems`,
            single: `${ENV.connections.api}/${version}/systems/%id%`,
            create: `${ENV.connections.api}/${version}/systems/create`,
            lastrejected: `${ENV.connections.api}/${version}/systems/%sid%/lastrejectedmanifest`,
            subsystems: `${ENV.connections.api}/${version}/systems/%id%/subsystems`,
            devices: `${ENV.connections.api}/${version}/systems/%id%/devices`,
            diagnostics: `${ENV.connections.api}/${version}/systems/%id%/diagnostics`,
            statusRecords: `${ENV.connections.api}/${version}/systems/%id%/status-records`,
            manifest: `${ENV.connections.api}/${version}/systems/%id%/manifest`,
            alerts: `${ENV.connections.api}/${version}/systems/%id%/alerts`,
            status: `${ENV.connections.api}/${version}/systems/%id%/status-records`,
            sid: {
                alerts: `${ENV.connections.api}/${version}/systems/%sid%/alerts`,
                status: `${ENV.connections.api}/${version}/systems/%sid%/status-records`,
                journal: `${ENV.connections.api}/${version}/systems/%sid%/journal`,
                uploads: {
                    primary: `${ENV.connections.api}/${version}/systems/%sid%/primary-uploads`
                }
            }
        },

        correlation: {
            list: `${ENV.connections.api}/${version}/correlations`,
            single: `${ENV.connections.api}/${version}/correlations/%id%`,
        },

        event: {
            list: `${ENV.connections.api}/${version}/events`,
        },

        statusRecord: {
            list: `${ENV.connections.api}/${version}/status-records`,
            recent: `${ENV.connections.api}/${version}/status-records/%id%/recent`,
        },

        diagnostic: {
            list: `${ENV.connections.api}/${version}/diagnostics`
        },

        device: {
            list: `${ENV.connections.api}/${version}/devices`,
            single: `${ENV.connections.api}/${version}/devices/%id%`,
        },

        password: {
            update: `${ENV.connections.api}/${version}/password/update`, // POST
            requestReset: `${ENV.connections.api}/${version}/password/reset`, // POST
            verifyReset: `${ENV.connections.api}/${version}/password/verify`, // POST
        },

        upload: {
            primary: {
                timeSeries: `${ENV.connections.api}/${version}/uploads/primary/time-series`,
                timeSeriesBySID: `${ENV.connections.api}/${version}/uploads/primary/time-series/%sid%`,
                recentBySID: `${ENV.connections.api}/${version}/uploads/primary/recent/%sid%`,
                devicesBySID: `${ENV.connections.api}/${version}/uploads/primary/%sid%/devices`
            }
        },
    }
}