import React, { FunctionComponent } from "react";
import { AppStore } from "../../../../AppStore";
import {
  GridFormCard
} from "../../../../components/gridManager/components/GridCard";
import { DeleteUtility } from "../../../../components/gridManager/components/DeleteUtility";
import { SystemService } from "../../../../services/system/SystemService";


interface Props {}

const LastRejectedManifest: FunctionComponent<Props> = (props) => {
  const [state, actions] = AppStore.systems.use();

  const handleSubmit = (system) => {
    return new Promise((resolve, reject) => resolve) //SystemService.updateOne(system)
      // .catch(console.log);
  };

  const handleUploadRejectedManifest = (sid) => () => {
      SystemService.uploadeLastRejectedManifest(sid)
        .catch(console.log);
}

  return (
    <GridFormCard
      model={state.highlighted}
      title="Last Rejected Manifest"
      onSubmit={handleSubmit}
    >
    <DeleteUtility
        requireConfirm
        onDelete={handleUploadRejectedManifest(state.highlighted?.sid)}
        title={`Are you sure you want to upload the last rejected manifest of ${state.highlighted?.sid}? (Non-reversible)`}
        text = "Upload Last Rejected Manifest"
      >
        Upload Last Rejected Manifest
        </DeleteUtility>
    </GridFormCard>
  );
};

export default LastRejectedManifest;